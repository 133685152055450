import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC } from 'react';

import { useAppSelector } from '../../../redux/hooks';

import * as img from '@assets/image/png';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Style } from '@features/e-cart/style';

interface Img {
  TemplateCard1: 'TemplateCard1';
  TemplateCard2: 'TemplateCard2';
  TemplateCard3: 'TemplateCard3';
  TemplateCard4: 'TemplateCard4';
  TemplateCard5: 'TemplateCard5';
  TemplateCard6: 'TemplateCard6';
  TemplateCard7: 'TemplateCard7';
  TemplateCard8: 'TemplateCard8';
}

const EGiftTemplateConfirmPage: FC = () => {
  const eGiftCard = useAppSelector((state) => state.eGiftCardReducer.eGiftCard);
  if (eGiftCard) {
    return (
      <Layout>
        <Style.Wrap>
          <Style.Container>
            <h5 className="text-center my-3 mx-auto" style={{ maxWidth: '300px' }}>
              <FormattedMessage id="e-gift.hint4" />
            </h5>
            <Style.GiftCard>
              <div className="template-header">
                {/* TODO temp use it will be adjusted when getting api spec  */}
                <img
                  src={(img as Img)[`TemplateCard${eGiftCard.templateIndex}` as keyof Img]}
                  alt={`TemplateCard${eGiftCard.templateIndex}`}
                />
              </div>
              <div className="template-content">
                <h6 className={`text-center font-weight-500 template${eGiftCard.templateIndex}`}>
                  <FormattedMessage id="mr" values={{ e: eGiftCard.receiver }} />
                </h6>
                <p
                  style={{ flexGrow: 1 }}
                  className={`text-justify template${eGiftCard.templateIndex}`}
                >
                  {eGiftCard.message}
                </p>
                <h6 className={`text-center font-weight-500 template${eGiftCard.templateIndex}`}>
                  {eGiftCard.sender}
                </h6>
              </div>
            </Style.GiftCard>
            <form>
              <div className="form-group">
                <Button
                  className="my-3"
                  width="250px"
                  onClick={() => {
                    navigate('/e-gift/template-complete');
                  }}
                >
                  <FormattedMessage id="order.next" />
                </Button>
                <Button
                  className="my-3 secondary"
                  width="180px"
                  onClick={() => {
                    navigate('/e-gift/template-select');
                  }}
                >
                  <FormattedMessage id="back.page" />
                </Button>
              </div>
            </form>
          </Style.Container>
        </Style.Wrap>
      </Layout>
    );
  }

  return (
    <Layout>
      <Style.Wrap>
        <Style.Container>
          <h5 className="text-center my-3 mx-auto" style={{ maxWidth: '300px' }}>
            <FormattedMessage id="e-gift.hint4" />
          </h5>
          <form>
            <div className="form-group">
              <Button
                className="my-3 secondary"
                width="180px"
                onClick={() => {
                  navigate('/e-gift/template-message');
                }}
              >
                <FormattedMessage id="back.page" />
              </Button>
            </div>
          </form>
        </Style.Container>
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftTemplateConfirmPage);
